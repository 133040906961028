import { useTripDesigner } from '../../../../hooks';
import { useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { useAsync } from 'react-async';
import { getHotels } from '../../../../api/hotels';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import { Spinner } from '@koob/margaret';
import { formatCurrency } from '../../../../utils';
import ComposeDayHotelCopyButton from './ComposeDayHotelCopyButton';
import ComposeDayItemRemoveButton from './ComposeDayItemRemoveButton';
import ComposeDayItem from './ComposeDayItem';
import ComposeDayItemSelector from './ComposeDayItemSelector';
import ComposeDayHotelSuggestedModal from './ComposeDayHotelSuggestedModal';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function ComposeDayHotel({ dayIndex, addHotel, date, getHotelDayIndex }) {
  const { t } = useTranslation('tripDesigner');
  const { tripFilters, selectedType, setTripData } = useTripDesigner();
  const [{ value: days }] = useField('days');
  const [{ value: hotel }, , { setValue: setHotel }] = useField(`days[${dayIndex}].hotel`);
  const [, , { setValue: setHotelIsIncluded }] = useField(`days[${dayIndex}].hotel.isIncluded`);
  const [{ value: priceLoading }, , { setValue: setPriceLoading }] = useField(`days[${dayIndex}].hotel.priceLoading`);
  const [{ value: experience }] = useField(`days[${dayIndex}].experience`);

  const program = experience?.programs?.[(experience?.dayIndex ?? 1) - 1];

  const [showModal, setShowModal] = useState(false);

  const suggestedHotelIds = [
    program?.accomodationHotel?.id,
    ...(program?.alternativeHotels ?? []).map(h => h.id),
  ].filter(Boolean);

  const hotelImage =
    hotel?.primaryAttachment?.imageUrl ??
    hotel?.imageUrl ??
    '/img/hotel-image-placeholder.png';

  const hotelDayIndex = useMemo(() => {
    return getHotelDayIndex(hotel, dayIndex);
  }, [days]);

  const requestFilters = {
    hotels: [hotel?.id],
    startAt: moment(date).format('YYYY-MM-DD'),
    endAt: moment(date).add(1, 'day').format('YYYY-MM-DD'),
    rooms: [{
      ...tripFilters?.composition,
      firstAdultNationality: tripFilters?.composition.firstAdultNationality?.value ?? ''
    }],
    cities: [],
    countries: [],
    kinds: [],
    regions: [],
    styles: [],
    sustainability: null,
  };

  const totalTravelers = tripFilters?.composition.count;

  const { data: priceData, reload: reloadPrice, isLoading } = useAsync({
    promiseFn: getHotels,
    filters: requestFilters,
    skip: !hotel?.id || program?.isIncludedAccomodation,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
    onResolve: response => {
      if (response?.data?.state === 'inProgress') {
        return setTimeout(() => reloadPrice(), 3000);
      }
      if (priceLoading) {
        setPriceLoading(false);
      }
    },
    onReject: () => {
      setTimeout(() => reloadPrice(), 5000);
    },
  });

  useEffect(() => {
    if (hotel?.id) {
      setPriceLoading(isLoading || priceData?.data?.state === 'inProgress');
    }
  }, [hotel?.id, isLoading]);

  useEffect(() => {
    if (hotel?.id && program?.isIncludedAccomodation !== undefined) {
      setHotelIsIncluded(program?.isIncludedAccomodation);
      setTripData(days);
    }
  }, [hotel?.id, program?.isIncludedAccomodation]);

  const priceObject = useMemo(
    () => priceData?.data?.data[0],
    [priceData?.data?.data],
  );

  if (hotel && priceObject) {
    hotel.price = priceObject?.price;
    hotel.currency = priceObject?.currency;
  }

  const removeHotel = () => {
    setHotel(null);
  };

  return !hotel ? (
    <div className="h-full">
      <ComposeDayHotelSuggestedModal
        open={showModal}
        close={() => setShowModal(false)}
        addHotel={(value) => {
          addHotel(value);
          setShowModal(false);
        }}
        hotelIds={suggestedHotelIds}
        isIncluded={program?.isIncludedAccomodation}
        date={date}
      />

      <ComposeDayItemSelector
        dayIndex={dayIndex}
        type="hotel"
        isIncluded={program?.isIncludedAccomodation}
        isFreeAccomodation={program?.accomodationType === 'freeAccomodation'}
        onSecondaryClick={() => setShowModal(true)}
      />
    </div>
  ) : (
    <ComposeDayItem>
      <a
        href={`/hotels/${hotel?.id}?source=trip`}
        target="_blank"
        className={'hover:text-blue-700'}
        rel="noreferrer"
      >
        <img
          src={hotelImage}
          onError={ev => ev.target.src = '/img/hotel-bg-placeholder.png'}
          className="absolute inset-0 -z-10 h-full w-full object-cover brightness-150 blur-lg rounded-lg"
        />

        <div className="relative">
          <ComposeDayItemRemoveButton
            onClick={() => removeHotel()}
          />

          {hotelDayIndex?.total >= 1 && (
            <div className="absolute bottom-0 right-0">
              <KoobBadge
                color="orange"
                size={'sm'}
              >
                {hotelDayIndex.current}/{hotelDayIndex.total}
              </KoobBadge>
            </div>
          )}

          {selectedType === 'hotel' && (
            <ComposeDayHotelCopyButton
              tipText={t('tooltip.duplicateHotel')}
              tipPosition={'left'}
              onClick={() => {
                addHotel(hotel);
              }}
            />
          )}

          <div className="flex space-x-2 pr-1.5">
            <div>
              <div className="relative h-10 w-10 rounded overflow-hidden">
                <img
                  src={hotelImage}
                  onError={ev => ev.target.src = '/img/hotel-image-placeholder.png'}
                  className="absolute inset-0 h-full w-full object-cover rounded"
                />
              </div>
            </div>

            <div>
              <div className="text-xs font-medium text-gray-900">
                {hotel?.displayName}
              </div>

                <div className="text-sm font-medium mt-2">
                  {!program?.isIncludedAccomodation ? (
                    <div className="text-orange-600">
                      {(!hotel?.price && isLoading) || priceData?.data?.state !== 'finished' || isNaN(hotel?.price) ? (
                        <Spinner size={15}/>
                      ) : (
                        <>
                          {formatCurrency({
                            amount: hotel?.price,
                            currency: hotel?.currency,
                          })}

                          {hotel?.price && (
                            <div className="text-xs text-gray-600">
                              {formatCurrency({
                                amount: hotel.price / totalTravelers,
                                currency: hotel.currency,
                              })} / PAX
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="text-green-500">
                      {t('compose.priceIncluded')}
                    </div>
                  )}
                </div>
            </div>
          </div>

          <div className="mt-1 text-xs">
            <div className="text-gray-500">
              {hotel?.address}
            </div>
          </div>
        </div>
      </a>
    </ComposeDayItem>
  )
}
