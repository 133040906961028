import { useTranslation } from 'react-i18next';
import { useTripDesigner } from '../../../../hooks';
import React, { useState } from 'react';
import { useField } from 'formik';

export default function ComposeDayItemSelector({ dayIndex, type, isIncluded, isFreeAccomodation, onSecondaryClick }) {
  const { t } = useTranslation('tripDesigner');
  const { selectedDay, selectedType, selectDay, resetDaySelection } = useTripDesigner();
  const [, , { setValue: setBookOnMyOwn }] = useField(`days[${dayIndex}].hotelBookedOnMyOwn`);
  const [{ value: experience }] = useField(`days[${dayIndex}].experience`);

  const program = experience?.programs?.[(experience?.dayIndex ?? 1) - 1];

  const [exploreHotels, setExploreHotels] = useState(true);

  const isSelected = selectedDay === dayIndex && selectedType === type;

  const hasSuggestedHotels = program?.accomodationHotel || program?.alternativeHotels?.length > 0;

  const onSelect = () => {
    if (!isSelected) {
      selectDay(dayIndex, type);
    } else {
      if (onSecondaryClick && exploreHotels && hasSuggestedHotels) {
        onSecondaryClick();
        setExploreHotels(false);
      } else {
        resetDaySelection();
        setExploreHotels(true);
      }
    }
  }

  return (
    <div
      id={`day-${dayIndex}-${type}-selector`}
      onClick={() => onSelect()}
      className={[
        'bg-gray-100 h-full p-5 rounded-md border-2 transition',
        isSelected
          ? [
            'animate-pulse',
            isIncluded
              ? 'border-green-500'
              : 'border-orange-500'
          ].join(' ')
          : 'cursor-pointer border-transparent hover:border-gray-300'
      ].join(' ')}
    >
      <div className="h-full flex justify-center items-center">
        <div className="text-gray-400 text-sm text-center">
          {
            type === 'experience'
              ? t('compose.addProgram')
              : (isSelected && exploreHotels && hasSuggestedHotels)
                ? isIncluded
                  ? t('compose.exploreIncludedHotels')
                  : t('compose.exploreSuggestedHotels')
                : t('compose.addHotel')
          }

          {isFreeAccomodation && (
            <button
              className="mt-1.5 border-none text-orange-500"
              onClick={(event) => {
                setBookOnMyOwn(undefined);
                resetDaySelection();

                event.stopPropagation();
              }}
            >
              {t('compose.resetChoice')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
