export const ExtraScopeEnum = {
  GLOBAL: 'global',
  DAY: 'day'
};

export const ExtraRequirementEnum = {
  REQUIRED: 'required',
  SUGGESTED: 'suggested',
};

export const ApiPollingStateEnum = {
  IN_PROGRESS: 'inProgress',
  FINISHED: 'finished'
};
