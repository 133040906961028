import { useTranslation } from 'react-i18next';
import { useSnack, useTripDesigner } from '../../../../hooks';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useAsync } from 'react-async';
import { getExperiences } from '../../../../api/experiences';
import { isEqual } from 'lodash';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import { Spinner } from '@koob/margaret';
import { formatCurrency } from '../../../../utils';
import { Tooltip } from '../../../../components';
import { IoWarningOutline } from 'react-icons/io5';
import ExperienceExtrasModal from '../../../Experiences/Partials/ExperienceExtrasModal';
import ComposeDayItemSelector from './ComposeDayItemSelector';
import ComposeDayItem from './ComposeDayItem';
import ComposeDayItemRemoveButton from './ComposeDayItemRemoveButton';
import ComposeDayExperienceOptionsButton from './ComposeDayExperienceOptionsButton';
import { ExtraScopeEnum } from '@koob/enums';

export default function ComposeDayExperience({
  dayIndex,
  date,
  setOptions,
  currentVersion,
  formatExtras,
  setFormatExtras
}) {
  const {
    tripFilters,
    initialExtras,
    setInitialExtras,
    selectedExtras,
    setSelectedExtras,
    lastAddedExperienceId
  } = useTripDesigner();
  const { t } = useTranslation('tripDesigner', { keyPrefix: 'compose' });
  const { notify } = useSnack();

  const [{ value: days }, , { setValue: setDays }] = useField('days');
  const [{ value: experience }, , { setValue: setExperience }] = useField(`days[${dayIndex}].experience`);
  const [, , { setValue: setExperienceSelectedExtras }] = useField(`days[${dayIndex}].experience.selectedExtras`);
  const [{ value: priceLoading }, , { setValue: setPriceLoading }] = useField(`days[${dayIndex}].experience.priceLoading`);
  const [{ value: nextExperience }] = useField(`days[${dayIndex + 1}].experience`);

  const [showExtrasModal, setShowExtrasModal] = useState(false);
  const [showDayOptions, setShowDayOptions] = useState(false);
  const [extraRecommendationMode, setExtraRecommendationMode] = useState(false);
  const [recommendedExtras, setRecommendedExtras] = useState([]);

  const requestFilters = {
    experiences: [experience?.id],
    experienceComposition: tripFilters?.composition,
    startAt:
      !experience?.dayIndex || experience?.dayIndex === 0
        ? moment(date).toISOString()
        : moment(date).subtract(experience?.dayIndex - 1, 'days').toISOString(),
    endAt:
      !experience?.dayIndex || experience?.dayIndex === 0
        ? moment(date).toISOString()
        : moment(date).subtract(experience?.dayIndex - 1, 'days').toISOString(),
    templateId: tripFilters?.templateId ?? undefined,
    isForTripSearch: true
  };

  const { data: priceData, isLoading: isLoadingPrice } = useAsync({
    promiseFn: getExperiences,
    filters: requestFilters,
    locale: 'en',
    skip: !experience?.id,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters)
    },
    onResolve: () => {
      if (priceLoading) {
        setPriceLoading(false);
      }
    },
    onReject: () => {
      if (priceLoading) {
        setPriceLoading(false);
      }
    },
  });

  useEffect(() => {
    if (experience?.id) {
      setPriceLoading(isLoadingPrice);
    }
  }, [isLoadingPrice]);

  const accomodationExtras = [...new Set(
    experience?.programs?.reduce((acc, program) => {
      const includedIds = program?.includedExperiences?.map(item => item.id);
      return acc.concat(includedIds);
    }, [])
  )];

  const suggestedExperiences =
    experience?.programs?.[experience?.dayIndex - 1]?.suggestedExperiences ?? [];

  let extrasList = (experience?.extras ?? [])
    .filter(extra => !accomodationExtras.includes(extra.id));
  if (showDayOptions) {
    extrasList = suggestedExperiences;
  }
  const hasSuggestedOptions = suggestedExperiences.length > 0;

  useEffect(() => {
    if (experience?.id && !experience.filters) {
      setExperience({
        ...experience,
        filters: {
          ...requestFilters,
          startAt: moment(date).toISOString(),
          endAt: moment(date).add(experience.durationDays ?? 1, 'day').toISOString(),
        },
        selectedExtras
      });
    }
  }, [experience?.id]);

  useEffect(() => {
    const priceObject = priceData?.data[0]?.price;

    const addDefaultOptions = () => {
      selectedExtras.forEach((opt, index) => {
        const optionData = priceObject?.extras?.find(extra => extra?.id === opt.id);

        if (optionData) {
          const value = {
            id: optionData.id,
            name: optionData?.name,
            experienceId: experience?.id,
            adultCount: tripFilters?.composition.adults,
            childrenBirthdates: tripFilters?.composition.childrenBirthdates,
            bestMatches: experience?.bestMatches ?? [],
            cancellationPolicies: opt?.cancellationPolicies,
            incompatibilities: experience?.incompatibilities ?? [],
            price: optionData?.pricings[0]?.price,
            priceWithoutPromo: optionData?.pricings[0]?.priceWithoutPromo,
            currency: priceObject?.currency,
            dayIndex: opt.dayIndex,
            scope: opt.scope
          };
          setOptions(index, value);
          setFormatExtras(false);
        }
      })
    }
    if (priceData?.data) {
      if (formatExtras) {
        addDefaultOptions();
      }
    }
  }, [dayIndex, priceData?.data, experience, currentVersion, formatExtras]);

  useEffect(() => {
    if (priceData?.data && initialExtras?.length > 0) {
      let someUnavailable = false;
      let untreated = [];

      initialExtras?.forEach((opt) => {
        // Should only be added to the day it's meant for
        if (opt.dayIndex !== dayIndex + 1) {
          untreated.push(opt);
          return;
        }

        const optionData = priceObject?.extras?.find(extra => extra?.id === opt.id);

        if (!optionData || optionData?.pricings?.length === 0) {
          someUnavailable = true;
          return;
        }

        if (optionData) {
          const value = {
            id: optionData.id,
            parentExtraId: opt.parentExtraId,
            name: optionData?.name,
            experienceId: experience?.id,
            adultCount: tripFilters?.composition.adults ?? 0,
            childrenBirthdates: tripFilters?.composition.childrenBirthdates ?? [],
            bestMatches: experience?.bestMatches ?? [],
            cancellationPolicies: opt?.cancellationPolicies,
            incompatibilities: experience?.incompatibilities ?? [],
            price: optionData?.pricings[0]?.price,
            priceWithoutPromo: optionData?.pricings[0]?.priceWithoutPromo,
            currency: priceObject?.currency,
            dayIndex: opt.dayIndex - 1,
            scope: opt.scope
          };

          setSelectedExtras(prev => [
            ...prev,
            value
          ]);

          setFormatExtras(false);
        }
      });

      setInitialExtras([
        ...untreated
      ]);

      if (someUnavailable) {
        notify(t('someOptionsUnavailable'), { type: 'error' });
      }
    }
  }, [priceData?.data]);

  useEffect(() => {
    if (days?.length >= tripFilters?.tripDuration) {
      const newDays = days.map((day, index) => {
        if (Object.keys(day).length === 0) {
          return index < tripFilters?.tripDuration ? {} : null;
        }
        return day;
      });
      setDays(
        newDays.filter(day => day || dayIndex < newDays.findIndex(day => day?.experience))
      );
    }
  }, [tripFilters]);

  const removeExperience = () => {
    const newDays = days.map((day, index) => {
      if (
        index >= dayIndex && index < dayIndex + experience.durationDays
        || index === dayIndex && experience.durationDays === 0
        || day?.experience?.id === experience?.id
      ) {
        return {
          experience: null,
          hotel: null,
        };
      }

      return day;
    });

    setDays(
      newDays.filter(day => day || dayIndex < newDays.findIndex(day => day?.experience))
    );

    const experienceDayIndexes = [];

    days?.map((day, index) => {
      if (day?.experience?.id === experience?.id) {
        experienceDayIndexes.push(index);
      }
    });

    setSelectedExtras(selectedExtras => {
      return selectedExtras
        .filter(extra => !experienceDayIndexes.includes(extra?.dayIndex) && extra?.experienceId !== experience?.id);
    });
  };


  const priceObject = priceData?.data[0]?.price;
  const currency = priceObject?.currency;
  const price =
    priceObject?.price
    + selectedExtras?.reduce((acc, extra) => {
      if (
        extra?.experienceId !== experience?.id &&
        extra?.parentExtraId !== experience?.id
      ) {
        return 0;
      }

      if (!extra?.perPax) {
        return acc + extra?.price;
      }
      return acc + extra?.price * (extra?.adults + extra?.childrenBirthdates?.length);
    }, 0);
  const priceWithoutPromo =
    priceObject?.totalPriceWithoutPromo
    + selectedExtras?.reduce((acc, extra) => {
      if (
        extra?.experienceId !== experience?.id &&
        extra?.parentExtraId !== experience?.id
      ) {
        return 0;
      }

      if (!extra?.perPax) {
        return acc + extra?.priceWithoutPromo;
      }
      return acc + extra?.priceWithoutPromo * (extra?.adults + extra?.childrenBirthdates?.length);
    }, 0);

  if (experience && price) {
    experience.tripPrice = {
      price,
      currency
    };
  }

  const isAvailable = experience?.state === 'available';

  const getTransitionOptions = (currentExp, nextExp) => {
    let options = [];

    currentExp?.extras?.map(e => {
      if (e?.recommendations?.find(r => r.id === nextExp?.id)) {
        options?.push(e);
      }
    });

    nextExp?.extras?.map(e => {
      if (e?.recommendations?.find(r => r.id === currentExp?.id)) {
        options?.push(e);
      }
    });

    return options.filter(Boolean);
  }

  useEffect(() => {
    if (
      experience?.id
      && experience?.extras
      && nextExperience?.extras
      && nextExperience?.id
    ) {
      const options = getTransitionOptions(experience, nextExperience);

      if (
        options.length > 0
        && !selectedExtras?.some(extra => options?.some(option => option.id === extra.id))
        && !experience?.skipTransitionOption
      ) {
        setRecommendedExtras(options);
        setExtraRecommendationMode(true);
        setShowExtrasModal(true);
      }
    }
  }, [experience?.extras, nextExperience?.extras]);

  useEffect(() => {
    if (!showExtrasModal && extraRecommendationMode) {
      setExtraRecommendationMode(false);
    }
  }, [showExtrasModal]);

  useEffect(() => {
    if (experience?.skipTransitionOption && !nextExperience?.id) {
      setExperience({
        ...experience,
        skipTransitionOption: undefined,
      });
    }
  }, [nextExperience?.id]);

  const totalTravelers = tripFilters?.composition?.count;

  const currentExtrasList = extraRecommendationMode
    ? recommendedExtras
    : extrasList.filter(e => {
      return !experience?.programs?.some((p) => {
        return (
          !showDayOptions
          && p.suggestedExperiences.some(exp => exp.id === e.id)
        );
      })
    });

  const globalExtrasCount = selectedExtras?.filter(extra => {
    return (
      extra?.experienceId === experience?.id
        && !accomodationExtras.includes(extra.id)
        && extra.dayIndex === dayIndex
        && extra.scope === ExtraScopeEnum.GLOBAL
    );
  })?.length;

  const dayExtrasCount = selectedExtras?.filter(extra => {
    return (
      suggestedExperiences?.some(exp => exp.id === extra.id || exp.id === extra.parentExtraId)
        && extra.dayIndex === dayIndex
        && extra.scope === ExtraScopeEnum.DAY
        && extra.experienceId === experience?.id
    )
  })?.length;

  const onCancelAdd = () => {
    if (lastAddedExperienceId) {
      const newDays = [...(days ?? [])]?.map(d => {
        if (d?.experience?.id === lastAddedExperienceId) {
          return { experience: null, hotel: null };
        }
        return d;
      });
      setDays(newDays);
      setShowExtrasModal(false);
    }
  };

  return (
    <div className="h-full">
      {!experience ? (
        <ComposeDayItemSelector
          dayIndex={dayIndex}
          type="experience"
        />
      ) : (
        <ComposeDayItem>
          <a
            href={`/experiences/${experience?.id}?source=trip`}
            target="_blank"
            className={'hover:text-blue-700'}
            rel="noreferrer"
          >
            <img
              src={experience?.imageUrl ?? experience?.pictures?.[0]?.imageUrl ?? '/img/hotel-image-placeholder.png'}
              onError={ev => ev.target.src = '/img/hotel-image-placeholder.png'}
              className="absolute inset-0 -z-10 h-full w-full object-cover brightness-150 blur-sm rounded-lg"
            />

            <div className="relative">
              {(!experience?.dayIndex || experience.dayIndex === 1) && (
                <ComposeDayItemRemoveButton
                  onClick={() => {
                    removeExperience()
                  }}
                />
              )}

              {experience?.durationDays > 1 && (
                <div className="absolute bottom-0 right-0">
                  <KoobBadge
                    color="orange"
                    size={'sm'}
                  >
                    {experience.dayIndex}/{experience.durationDays}
                  </KoobBadge>
                </div>
              )}

              <div className="flex space-x-2 pr-1.5">
                <div>
                  <div className="relative h-12 w-12 rounded overflow-hidden">
                    <img
                      src={experience?.imageUrl ?? experience?.pictures?.[0]?.imageUrl ?? '/img/hotel-image-placeholder.png'}
                      onError={ev => ev.target.src = '/img/hotel-image-placeholder.png'}
                      className="absolute inset-0 h-full w-full object-cover rounded"
                    />
                  </div>
                </div>

                <div>
                  <div className="text-sm font-medium text-gray-900">
                    {experience.name}
                  </div>

                  {(!experience?.dayIndex || experience.dayIndex === 1) && (
                    <div className="flex justify-between pr-1.5">
                      <div className="text-sm font-medium">
                        {priceLoading && (
                          <div className="my-1.5 pl-5">
                            <Spinner size={15}/>
                          </div>
                        )}

                        {(!priceLoading && price && !isNaN(price)) ? (
                          <>
                            {price !== priceWithoutPromo && (
                              <div className="text-xs text-gray-500 line-through">
                                {formatCurrency({
                                  amount: priceWithoutPromo,
                                  currency: currency,
                                })}
                              </div>
                            )}

                            <div className="text-orange-600">
                              {formatCurrency({
                                amount: price,
                                currency: currency,
                              })}
                            </div>

                            <div className="text-xs text-gray-600">
                              {formatCurrency({
                                amount: price / totalTravelers,
                                currency: currency,
                              })} / PAX
                            </div>
                          </>
                        ) : null}
                      </div>

                      <div className="absolute right-0">
                        {(!priceLoading && (!isAvailable || isNaN(price))) && (
                          <Tooltip
                            tip={t('noAvailable')}
                            color="red"
                            hasArrow={false}
                            position="left"
                          >
                            <IoWarningOutline color="red" size={20}/>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>
                {(!experience?.dayIndex || experience.dayIndex === 1) && (
                  <ComposeDayExperienceOptionsButton
                    label={t('manageOptions', { type: 'Global' })}
                    count={globalExtrasCount}
                    onSave={() => {
                      setShowExtrasModal(true);
                      setShowDayOptions(false);
                    }}
                  />
                )}

                {(experience?.dayIndex && hasSuggestedOptions) && (
                  <ComposeDayExperienceOptionsButton
                    label={t('manageOptions', {
                      type: `${t('day')} ${experience?.dayIndex}`,
                    })}
                    type="day"
                    count={dayExtrasCount}
                    onSave={() => {
                      setShowDayOptions(true);
                      setShowExtrasModal(true);
                    }}
                  />
                )}
              </div>
            </div>

            <ExperienceExtrasModal
              open={showExtrasModal}
              setOpen={setShowExtrasModal}
              experience={experience}
              experienceComposition={tripFilters?.composition}
              extrasScope={showDayOptions ? ExtraScopeEnum.DAY : ExtraScopeEnum.GLOBAL}
              extras={currentExtrasList}
              selectedExtras={selectedExtras}
              setSelectedExtras={setSelectedExtras}
              prices={priceObject?.extras}
              currency={priceObject?.currency}
              onContinue={() => setExperienceSelectedExtras(selectedExtras)}
              recommendationMode={extraRecommendationMode}
              dayIndex={dayIndex}
              showPaxUnmetWarning={false}
              source="trip"
              nextExperience={nextExperience}
              onCancelAdd={onCancelAdd}
            />
          </a>
        </ComposeDayItem>
      )}
    </div>
  );
}
