import React from 'react';
import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { Stack } from '@koob/margaret';
import { MdTrendingFlat } from 'react-icons/md';
import { media } from 'ui';
import { Button, Buttons, Grid, Cell } from 'components/Hotel/Booking/components';
import { useTranslation } from 'react-i18next';
import placeholderHotel from '../../../images/placeholderHotel.png';
import { formatCurrency, formatDate } from 'utils';
import CheckboxField from 'components/Checkbox';
import { useBooking, useExperienceBooking } from 'hooks';
import { IoMoonOutline, IoPeopleOutline } from 'react-icons/io5';
import { IcOrganization } from 'components/icons';
import { differenceInDays } from 'date-fns';
import { Promotion } from './RoomCards';
import { useNavigate } from 'react-router-dom';
import { getTravelerComposition } from '../../../providers/BookingProvider';
import Tooltip from 'components/Tooltip';
import KoobButton from '../../Koob/KoobButton';

const NewButtons = styled(Buttons)`
    margin-top: 10px;
`;
const HotelPic = styled.div`
    background-image: url("${props => props.image}");
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 145px;
`;

const Conditions = styled.div`
    margin-bottom: 0.6rem;
    color: #d11e1f;
`;

const TitleCard = styled.div`
    font-weight: 800;
    font-size: 12px;
    text-transform: uppercase;
`;

const NormalContent = styled.div`
    margin-top: 0px !important;
    display: flex;
    align-items: center
`;

const LightContent = styled.div`
    color: ${({theme}) => theme.textLighter};
    flex: 1;
`;

const HotelName = styled.div`
    font-size: 20px;
    font-weight: 700;
    margin-top: ${({theme}) => theme.spacing()};
`;

const HotelAdress = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-top: ${({theme}) => theme.spacing(0.5)};
`;

export const WrapperPrice = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0;
`;

export const TotalPrice = styled.div`
    text-transform: uppercase;
    font-weight: bold;

    ${props => props.orange && css`
        color: orange;
    `}

`;

export const WithoutPromo = styled.div`
    text-transform: uppercase;
    text-decoration: line-through;
    margin-left: 15px;
`;

const LinkCond = styled.span`
    font-weight: 800;
    border-bottom: 1px solid #000;
    text-transform: lowercase;
`

export const BookingAmount = styled.div`
    color: gray;
    font-weight: 800;
    ${props => props.bold && css`
        font-size: 30px;
    `}
`;

const HotelRoomPromotion = ({name, bgColor}) => {
  return (
    <Promotion bgColor={bgColor} promo>
      <span>{name}</span>
    </Promotion>
  );
};

export const HotelRoomPromotionSupplement = ({supplement, label}) => {
  return (
    <Tooltip tip={label} position="top" hasArrow={false} color="purple">
      <HotelRoomPromotion bgColor="#3AEDCC" name={supplement}/>
    </Tooltip>
  );
};

const HotelBookedSummary = ({
                              hotel,
                              startDate,
                              endDate,
                              rooms,
                              totalRoomNumber,
                              disabledButton,
                              step,
                              isInfo,
                              roomsFilter,
                              handleNextStep = () => {
                              },
                              children,
                            }) => {
  const {t} = useTranslation('booking', {keyPrefix: 'summary'});
  const hotelName = hotel?.displayName;
  const hotelAddress = hotel?.address;
  const img = get(hotel, 'primaryAttachment.imageUrl');
  const {currentSelectedRoom, selectedRooms, requestId, filters} = useBooking();
  const {basket, updateExperienceInBasket} = useExperienceBooking();
  const navigate = useNavigate();
  const nights = differenceInDays(new Date(endDate), new Date(startDate));

  const basketHotel = basket?.find(basketExperience =>
    basketExperience.internalId === filters.internalId);
  if (basketHotel) {
    totalRoomNumber = basketHotel?.filters?.experienceComposition?.length;
  }
  const getButtonLabel = () => {
    switch (step) {
      case 1:
        return ((rooms?.length === (totalRoomNumber - 1)) && currentSelectedRoom ? t('validate') : t('continueSelection'));
      case 2:
        return t('confirmBooking');
      default:
        return t('confirmBooking');
    }
  }

  let originalTotalPriceWithoutPromo = 0;
  let originalTotalPriceWithPromo = 0;
  let currency = undefined;

  if (!originalTotalPriceWithPromo && ((rooms && rooms.length > 0) || currentSelectedRoom)) {
    if (step === 1 && currentSelectedRoom) {
      originalTotalPriceWithPromo += parseFloat(currentSelectedRoom?.bed?.totalPriceWithPromo);
      originalTotalPriceWithoutPromo += parseFloat(currentSelectedRoom?.bed?.totalPriceWithoutPromo);
      currency = currentSelectedRoom?.bed?.currency;
    }
    if (rooms) {
      rooms.forEach((room) => {
        originalTotalPriceWithPromo += parseFloat(room?.bed?.totalPriceWithPromo);
        originalTotalPriceWithoutPromo += parseFloat(room?.bed?.totalPriceWithoutPromo);
        currency = room?.bed?.currency;
      });
    }
  }

  const handleChooseRoom = () => {
    let travelers = getTravelerComposition({
      compositions: [
        {
          adults: basketHotel.filters.experienceComposition[basketHotel?.selectedRooms?.length ?? 0].adults,
          children:
          basketHotel.filters.experienceComposition[basketHotel?.selectedRooms?.length ?? 0].childrenBirthdates
            .length,
        },
      ],
      customerFile: basketHotel.clientFolder,
      typeFieldName: 'kind',
    })[0].travelers;
    updateExperienceInBasket({
      ...basketHotel,
      selectedRooms: [...basketHotel.selectedRooms ?? [], {...currentSelectedRoom, travelers: travelers}],
      newRoom: true,
      requestId: requestId
    });
    if ((basketHotel?.selectedRooms?.length ? basketHotel?.selectedRooms?.length + 1 : 1) === basketHotel.filters?.experienceComposition?.length) {
      navigate('/experiences/checkout')
    } else {
      handleNextStep(basketHotel?.filters?.experienceComposition?.length)
    }
  };

  const totalTravelers = Math.max(
    roomsFilter?.reduce((acc, curr) => {
      acc += curr.adults;
      acc += curr.children;
      return acc;
    }, 0)
    , 1
  );

  const totalChildTravelers = roomsFilter?.reduce((acc, curr) => {
    acc += curr.children;
    return acc;
  }, 0);

  return (
    <div className="bg-white rounded-lg py-5 px-6 shadow-k">
      <div>
        <Grid>
          <Cell sizes={{default: 1, tabletprod: 1 / 2}}>
            {
              Boolean(img) ? <HotelPic image={img} alt=""/> : <HotelPic image={placeholderHotel} alt=""/>
            }
          </Cell>

          <Cell sizes={{default: 1, tabletprod: 1 / 2}}>
            <HotelName>
              {hotelName}
            </HotelName>

            <HotelAdress>
              {hotelAddress}
            </HotelAdress>
          </Cell>
        </Grid>

        <div className="pb-3 flex-col space-y-3">
          <TitleCard>
            {t('stay')}
          </TitleCard>

          {(startDate && endDate) && <NormalContent direction="row">
            {formatDate(
              startDate, 'dd/MM/yyyy'
            )} <MdTrendingFlat size={40} color={'#D6D6D6'}/> {formatDate(endDate, 'dd/MM/yyyy')}
          </NormalContent>}

          {(startDate && endDate) && <Stack alignY="center" gutterSize={0.5} style={{marginTop: '0px'}}>
            <IoMoonOutline size={18}/>
            <span>
              {t('nNightsStay', {
                count: nights,
                days: nights + 1
              })}
            </span>
          </Stack>
          }

          {(roomsFilter) && (
            <div className="mt-1 py-2 px-5 text-gray-500 border rounded flex justify-between items-center">
              <div>
                <i className="far fa-person mr-1.5" />
                {t('nAdults', {count: totalTravelers - totalChildTravelers})}
              </div>

              <div>
                <i className="far fa-child-reaching mr-1.5" />
                {t('nChildren', {count: totalChildTravelers})}
              </div>

              <div>
                <i className="far fa-bed-bunk mr-1.5" />
                {t('nRooms', {count: roomsFilter.length})}
              </div>
            </div>
          )}

          {((rooms && rooms.length > 0) || currentSelectedRoom) && (
            <TitleCard>{t('roomtitle')}</TitleCard>
          )}

          {rooms && (
            <>
              {rooms.map((room, descriptionKey) => (
                <div key={descriptionKey}>
                  <div
                    key={descriptionKey}
                    className="flex justify-between items-center"
                  >
                    <LightContent>
                      {room.name} {room?.bed?.name ? `(${room.bed.name})` : ''}
                    </LightContent>

                    <div className="text-sm font-medium text-right">
                      {currency && (
                        <div>
                          <div className="text-orange-600">
                            {formatCurrency({
                              amount: room?.bed?.totalPriceWithPromo,
                              currency: room.bed.currency,
                            })}
                          </div>

                          <div className="text-xs text-gray-600">
                            {formatCurrency({
                              amount: room?.bed?.totalPriceWithPromo / totalTravelers,
                              currency: room.bed.currency,
                            })} / PAX
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {(room?.bed?.promotions?.length > 0 ||
                    room?.bed?.mandatorySupplements?.length > 0) && (
                    <div className="flex flex-wrap gap-1">
                      {room?.bed?.promotions?.map((name, index) => (
                        <HotelRoomPromotion key={index} name={name}/>
                      ))}

                      {selectedRooms?.[descriptionKey]?.optionalPromos?.map(({displayName}, index) => (
                        <HotelRoomPromotion key={`supp_${index}`} name={displayName}/>
                      ))}

                      {room?.bed?.mandatorySupplements?.map((name, index) => (
                        <HotelRoomPromotionSupplement
                          key={index}
                          supplement={name}
                          label={t('requiredSupplement')}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))}

              {step === 1 && currentSelectedRoom && (
                <div className="flex justify-between items-center">
                  <LightContent>
                    {currentSelectedRoom.name} {currentSelectedRoom?.bed?.name ? `(${currentSelectedRoom.bed.name})` : ''}
                  </LightContent>

                  <div className="text-sm font-medium text-right">
                    {currency && (
                      <div>
                        <div className="text-orange-600">
                          {formatCurrency({
                            amount: currentSelectedRoom?.bed?.totalPriceWithPromo,
                            currency: currentSelectedRoom?.bed?.currency,
                          })}
                        </div>

                        <div className="text-xs text-gray-600">
                          {formatCurrency({
                            amount: currentSelectedRoom?.bed?.totalPriceWithPromo / totalTravelers,
                            currency: currentSelectedRoom?.bed?.currency,
                          })} / PAX
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {currentSelectedRoom?.bed?.name && ((currentSelectedRoom?.bed?.promotions?.length > 0 &&
              rooms.length < roomsFilter.length) ||
            (currentSelectedRoom?.bed?.mandatorySupplements?.length > 0 &&
              rooms.length < roomsFilter.length) ||
            (currentSelectedRoom?.optionalPromos?.length > 0 &&
              rooms.length < roomsFilter.length)) && (
            <div className="flex flex-wrap gap-1">
              {currentSelectedRoom?.bed?.promotions?.map((name, index) => {
                if (name !== null && !currentSelectedRoom?.bed?.optionalSupplements.some(item => item?.name === name)) {
                  return <HotelRoomPromotion key={index} name={name}/>
                }
              })}

              {currentSelectedRoom?.optionalPromos?.map(({displayName}, index) => (
                <HotelRoomPromotion key={`supp_${index}`} name={displayName}/>
              ))}

              {currentSelectedRoom?.bed?.mandatorySupplements?.map(
                (name, index) => (
                  <HotelRoomPromotionSupplement
                    key={index}
                    supplement={name}
                    label={t('requiredSupplement')}
                  />
                ),
              )}
            </div>
          )}

          <Stack direction="column" style={{marginTop: '20px'}}>
            <div style={{width: '100%', marginTop: 0}}>
              {Boolean(originalTotalPriceWithPromo) && (
                <>
                  <WrapperPrice style={{marginTop: 0}}>
                    <TotalPrice>
                      {t('total')} :
                    </TotalPrice>

                    {originalTotalPriceWithoutPromo !== originalTotalPriceWithPromo && (
                      <WithoutPromo bold className="w-fit">
                        {formatCurrency({
                          amount: originalTotalPriceWithoutPromo,
                          currency: currency
                        })}
                      </WithoutPromo>
                    )}

                    <div className="font-semibold text-right">
                      {!currency ? 0 : (
                        <div>
                          <div className="text-orange-600">
                            {formatCurrency({
                              amount: originalTotalPriceWithPromo,
                              currency: currency,
                            })}
                          </div>

                          <div className="text-xs text-gray-600">
                            {formatCurrency({
                              amount: originalTotalPriceWithPromo / totalTravelers,
                              currency: currency,
                            })} / PAX
                          </div>
                        </div>
                      )}
                    </div>
                  </WrapperPrice>
                </>
              )}
            </div>
          </Stack>
        </div>

        {children && children}

        {isInfo ?
          <>
            <CheckboxField
              inCardPay
              name="policiesConsent"
              label={t('infocheck')}
            />
            <LinkCond>{t('infocheckcond')}</LinkCond>
          </>
          : ''
        }

        {isInfo ? (
          <Conditions>{t('conditions')}</Conditions>
        ) : null}
        <NewButtons>
          <KoobButton
            onClick={(basketHotel) ? handleChooseRoom : handleNextStep}
            disabled={disabledButton}
            block
          >
            {getButtonLabel()}
          </KoobButton>
        </NewButtons>
      </div>
    </div>
  );
};

export default HotelBookedSummary;
