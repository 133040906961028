import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { useTripDesigner } from '../../../../hooks';

export default function ComposeDayHotelFreeAccomodationChoice({ dayIndex }) {
  const { t } = useTranslation('tripDesigner');
  const { selectDay, setListMode } = useTripDesigner();
  const [, , { setValue: setBookOnMyOwn }] = useField(`days[${dayIndex}].hotelBookedOnMyOwn`);
  const [{ value: experience }] = useField(`days[${dayIndex}].experience`);

  const program = experience?.programs?.[(experience?.dayIndex ?? 1) - 1];

  return (
    <div className="bg-gray-100 h-full p-3 rounded-md border-2 transition">
      <div className="h-full flex flex-col justify-center items-center">
        <div className="text-sm text-center text-gray-500">
          <button
            className="border-none hover:text-orange-500 transition"
            onClick={() => setBookOnMyOwn(true)}
          >
            {t('compose.bookOnMyOwn')}
          </button>

          <div className="my-1 text-gray-400">
            — {t('compose.or')} —
          </div>

          <button
            className="border-none hover:text-orange-500 transition"
            onClick={() => {
              setBookOnMyOwn(false);
              selectDay(dayIndex, 'hotel');

              if (program && program?.alternativeHotels?.length === 0) {
                setListMode('list');
              }
            }}
          >
            {t('compose.selectFromMarketplace')}
          </button>
        </div>
      </div>
    </div>
  )
}
