import { useTripDesigner } from '../../../../hooks';
import { getTrip } from '../../../../api/trip';
import { useAsync } from 'react-async';
import { isEqual } from 'lodash';
import ComposeDayReadOnly from './ComposeDayReadOnly';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import KoobPlaceholder from '../../../../components/Koob/KoobPlaceholder';
import { formatTripFromApi } from '../../utils';
import ExperienceProgramContentHeader from '../../../Experiences/Partials/ExperienceProgramContentHeader';
import { SeparatorLine } from '@koob/ui';

export default function ComposeVersionCompare({ tripId, getHotelDayIndex }) {
  const { t } = useTranslation('tripDesigner');
  const { compareWithVersion, tripFilters } = useTripDesigner();
  const [days, setDays] = useState([]);
  const [extras, setExtras] = useState([]);
  const [fetching, setFetching] = useState(true);

  const { data, isLoading } = useAsync({
    promiseFn: getTrip,
    tripId,
    versionId: compareWithVersion?.id,
    watchFn: (props,prevProps) => {
      return !isEqual(props.tripId, prevProps.tripId)
      || !isEqual(props.versionId, prevProps.versionId)
    }
  });

  const trip = data?.data;

  useEffect(async () => {
    const { days, initialExtras } = await formatTripFromApi(trip);

    setFetching(false);

    setDays(days);
    setExtras(initialExtras);
  }, [trip]);

  return (
    <div className="mt-5">
      {(!isLoading && !fetching) ? (
        <>
          <ExperienceProgramContentHeader
            title={t('compose.arrival')}
            date={tripFilters.departureDate}
            location={tripFilters.startLocation?.title}
          />

          <div>
            {trip?.tripDays?.length === 0 && (
              <div className="text-gray-500 text-sm">
                {t('compose.versions.emptyDays')}
              </div>
            )}

            <SeparatorLine />

            {days?.map((day, i) => (
              <ComposeDayReadOnly
                key={i}
                dayIndex={i}
                experience={day?.experience}
                extras={extras?.filter((extra) => extra.dayIndex === i + 1)}
                hotel={day?.hotel}
                date={moment(tripFilters.departureDate).add(i, 'days')}
                getHotelDayIndex={getHotelDayIndex}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <KoobPlaceholder className="h-14 rounded-md w-full" />

          <div className="mt-5 flex flex-col space-y-5">
            <KoobPlaceholder className="h-44 rounded-md w-full" />

            <KoobPlaceholder className="h-32 rounded-md w-full" />

            <KoobPlaceholder className="h-32 rounded-md w-full" />
          </div>
        </>
      )}
    </div>
  );
}
