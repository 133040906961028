import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { EditorField } from '../../../Fields';
import { createTripDocuement } from '../../../../api/trip';
import ChatboxUpload from '../../../Hotel/Booking/Edit/Chat/Partials/ChatboxUpload'
import { useError } from '../../../../hooks';

export default function ChatboxInput({
  tripId,
  conversationId,
  sendMessage,
  refetch,
}) {
  const { t } = useTranslation('tripDesigner');
  const [processing, setProcessing] = useState(false);
  const { sendErrorSnack } = useError();

  const initialesValuesDocument = {
    tripId,
    conversationId,
    document: null,
  };
  const onSubmitDocument = async values => {
    setProcessing(true);

    try {
      const body = new FormData();

      body.append('input', JSON.stringify(values));
      body.append('document', values.document);

      await createTripDocuement({
        tripId,
        conversationId,
        data: body
      });
      refetch();
    } catch (error) {
      sendErrorSnack(error);
    }
    setProcessing(false);
  };

  return (
    <Formik
      initialValues={{
        tripId,
        conversationId,
        title: 'message',
        message: '',
      }}
      onSubmit={(values, { resetForm }) =>
        sendMessage(values).then(() => resetForm())
      }
    >
      <Form>
        <div className="min-h-20 px-5 py-4 border-t">
          <div className="h-full flex items-center space-x-4">

            <ChatboxUpload
              processing={processing}
              initialesValuesDocument={initialesValuesDocument}
              onSubmitDocument={onSubmitDocument}
              conversationId={conversationId}
            />

            <div className='w-full h-[8rem]'>
              <EditorField
                name="message"
                enchanced
                variant="complete"
                placeholder={t('conversation.send')}
                style={{ height: '100px' }}
                fillHeight
              />
            </div>

            <button className="tw border-none outline-none" type="submit">
              <svg className="h-8 w-8 text-gray-500" viewBox="0 0 512 512">
                <path d="M492.6 226.6L44.6 34.59C40.54 32.85 36.26 31.1 32.02 31.1c-8.623 0-17.1 3.499-23.3 10.05C-.4983 51.81-2.623 66.3 3.377 78.31L96 256l-92.62 177.7c-6 12.02-3.875 26.5 5.344 36.27c6.188 6.547 14.66 10.05 23.28 10.05c4.25 0 8.531-.8438 12.59-2.594L492.6 285.4c11.78-5.031 19.41-16.61 19.41-29.41C511.1 243.2 504.4 231.6 492.6 226.6zM31.98 64.03C31.99 64.01 31.96 64.04 31.98 64.03L442.7 240H123.7L31.98 64.03zM31.75 448.5L123.7 272h318.1L31.75 448.5z" />
              </svg>
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
