import { get, post, put, putFile } from './';

export const getTrip = ({ tripId, versionId }) => {
  return get(`/api/v1/trip/${tripId}${versionId ? `?version=${versionId}` : ''}`);
}

export const getOneTrip = ({tripId}) => {
  return get(`/api/v1/trip/${tripId}`);
}

export const saveTrip = (trip) => {
  return post(`/api/v1/trip/save`, trip);
};

export const saveTripDays = ({ tripId, tripDays, folderId, draftPrice, travelers, globalOptions }) => {
  return put(`/api/v1/trip/${tripId}/days`, {tripDays, folderId, draftPrice, travelers, globalOptions});
}

export const saveTripTemplate = ({ tripId, body }) => {
  return post(`/api/v1/trip/${tripId}/template`, body);
}

export const archiveTripTemplate = ({ tripId, templateId }) => {
  return post(`/api/v1/trip/${tripId}/templates/${templateId}/archive`);
}

export const restoreTripTemplate = ({ tripId, templateId }) => {
  return post(`/api/v1/trip/${tripId}/templates/${templateId}/restore`);
}

export const getTripTemplate = () => {
  return get(`/api/v1/templates`);
}

export const getTripPrograms = ({filters, locale}) => {
  return post(`/api/v1/trip/programs?locale=${locale ?? 'en'}`, filters);
};

export const getTripsBooked = ({filters, locale}) => {
  return post(`/api/v1/trip/booked?locale=${locale ?? 'en'}`, filters);
};

export const getTripConversations = ({ tripId, filters }) => {
  return post(`/api/v1/trip/${tripId}/conversation`, filters);
}

export const getTripConversationMessage = ({ tripId, conversationId }) => {
  return get(`/api/v1/trip/${tripId}/conversation/${conversationId}/message`);
}

export const sendMessage = ({ tripId, conversationId, body }) => {
  return post(`/api/v1/trip/${tripId}/conversation/${conversationId}/message/`, body);
}

export const createTripConversations = ({ tripId, body }) => {
  return post(`/api/v1/trip/${tripId}/conversation/create`, body);
}

export const closeTripConversations = (tripId, conversationId) => {
  return put(`/api/v1/trip/${tripId}/conversation/${conversationId}`);
}

export const getTripDocuments = ({ tripId, conversationId, }) => {
  return get(`/api/v1/trip/${tripId}/conversation/${conversationId}/document`);
}

export const createTripDocuement = ({ tripId, conversationId, data}) => {
  return putFile(`/api/v1/trip/${tripId}/conversation/${conversationId}/document`, data);
}

export const getConversation = (conversationId) => {
  return get(`/api/v1/trip/conversations/${conversationId}`);
}

