import { useMemo, useState } from 'react';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import { useTranslation } from 'react-i18next';
import ExperienceProgramContentHeader from '../../../Experiences/Partials/ExperienceProgramContentHeader';
import { getExperiences } from 'api/experiences';
import { useAsync } from 'react-async';
import moment from 'moment';
import { useTripDesigner } from 'hooks';
import { formatCurrency } from 'utils';
import { getHotels } from 'api/hotels';
import { isEqual, omit } from 'lodash';
import { Spinner } from '@koob/margaret';
import ComposeDayExperienceOptionsButton from './ComposeDayExperienceOptionsButton';
import ExperienceExtrasModal from 'containers/Experiences/Partials/ExperienceExtrasModal';
import { getPricingForCompositionExtra } from 'containers/Experiences/utils';
import { SeparatorLine } from '@koob/ui';
import { ProgramAccommodationTypeEnum } from '@koob/experiences';
import { ExtraScopeEnum } from '@koob/enums';

function Selector({ type }) {
  const { t } = useTranslation('tripDesigner');

  return (
    <div
      className={[
        'bg-gray-100 h-full p-5 rounded-md border-2 transition',
        'border-transparent'
      ].join(' ')}
    >
      <div className="h-full flex justify-center items-center">
        <div className="text-gray-400 text-sm text-center">
          {type === 'experience' ? t('compose.noProgram') : t('compose.noAccomodation')}
        </div>
      </div>
    </div>
  )
}

function ComposeDayItemCard({ children }) {
  return (
    <div className="h-full relative overflow-hidden p-2.5 bg-gradient-to-t from-white/40 to-white border rounded-md min-h-32">
      {children}
    </div>
  )
}

function updateTotalPrice(prev, priceData) {
  const prevPrice = Number(prev?.price ?? 0);
  const prevCurrency = prev?.currency ?? '';
  let newPrice = Number(priceData?.price ?? 0);
  const newCurrency = priceData?.currency ?? prevCurrency;

  let currency;
  if (prevCurrency && newCurrency && prevCurrency !== newCurrency) {
    currency = 'mismatch';
  } else {
    currency = newCurrency;
  }

  return {
    price: prevPrice + newPrice,
    currency: currency,
  };
}

function ComposeDaySelectorExperience({
  dayIndex,
  experience,
  extras,
  date,
}) {
  const {tripFilters, setCompareVersionTotalPrice} = useTripDesigner();
  const [experienceTotalPrice, setExperienceTotalPrice] = useState();
  const [showExtrasModal, setShowExtrasModal] = useState(false);
  const [showDayOptions, setShowDayOptions] = useState(false);
  const allSelectedExtras  = [
    ...(experience.selectedDayOptions || []),
    ...(experience?.dayIndex === 1 ? (experience?.globalOptions || []) : [])
  ]


  const { t } = useTranslation('tripDesigner', { keyPrefix: 'compose' });

  const experienceComposition = tripFilters?.composition;

  const requestFilters = {
    experiences: [experience?.id],
    experienceComposition: experienceComposition ?? undefined,
    startAt:
      !experience?.dayIndex || experience?.dayIndex === 0
        ? moment(date).toISOString()
        : moment(date).subtract(experience?.dayIndex - 1, 'days').toISOString(),
    endAt:
      !experience?.dayIndex || experience?.dayIndex === 0
        ? moment(date).toISOString()
        : moment(date).subtract(experience?.dayIndex - 1, 'days').toISOString(),
    isForTripSearch: true
  };

  const { data: priceData, isLoading: isLoadingPrice } = useAsync({
    promiseFn: getExperiences,
    filters: requestFilters,
    locale: 'en',
    skip: !experience?.id,
    onResolve: (data) => {
      const experiencePrice = {
        price: data?.data?.[0]?.price?.price ?? 0,
        currency: data?.data?.[0]?.price?.currency
      };
      const selectedOptions = [
        ...(experience.selectedDayOptions || []),
        ...(experience?.dayIndex === 1 && (experience?.globalOptions || []))
      ];

      const basePrice = experience?.dayIndex === 1 && (experiencePrice.price ?? 0);

      const extrasPrice =
       data?.data?.[0]?.price?.extras?.reduce((total, extra) => {
        const isIncludedExtra = selectedOptions.some(
          (option) => option?.id === extra?.id,
        );
        const priceObj = getPricingForCompositionExtra({
          composition: experienceComposition,
          pricings: extra?.pricings,
        });

        return isIncludedExtra
          ? total + (priceObj?.price ?? 0)
          : total;
      }, 0) ?? 0;

      const totalPrice = basePrice + extrasPrice;

      setCompareVersionTotalPrice(prevCompare => updateTotalPrice(prevCompare, {
        price: totalPrice,
        currency: experiencePrice.currency
      }));

      setExperienceTotalPrice(prev => ({
        price: (prev?.price ?? 0) + totalPrice,
        currency: experiencePrice.currency
      }));
    }
  });

  const experiencePrice = priceData?.data?.[0]?.price;

  const globalExtrasCount = extras?.filter((global) => global.scope === ExtraScopeEnum.GLOBAL)?.length;
  const dayExtrasCount = extras?.filter((day) => day.scope === ExtraScopeEnum.DAY)?.length;

  const accomodationExtras = [...new Set(
    experience?.programs?.reduce((acc, program) => {
      const includedIds = program?.includedExperiences?.map(item => item.id);
      return acc.concat(includedIds);
    }, [])
  )];

  const suggestedExperiences =
    experience?.programs?.[experience?.dayIndex - 1]?.suggestedExperiences ?? [];
  let extrasList = (experience?.extras ?? [])
    .filter(extra => !accomodationExtras.includes(extra.id));
  if (showDayOptions) {
    extrasList = suggestedExperiences;
  }

  const currentExtrasList =  extrasList.filter(e => {
    return !experience?.programs?.some(p => {
      return (
        !showDayOptions
        && p.suggestedExperiences.some(exp => exp.id === e.id)
      );
    })
  })

  const formatSelectedExtras = () => {
    return (experiencePrice?.extras ?? []).reduce((acc, extra) => {
      const selectedExtra = allSelectedExtras.find(
        (option) => option.id === extra.id,
      );

      if (selectedExtra) {
        const price = getPricingForCompositionExtra({
          composition: experienceComposition,
          pricings: extra?.pricings,
        });

        acc.push({
          ...omit(extra, 'pricings'),
          ...price,
          ...experienceComposition,
          adultCount: experienceComposition?.adults,
          experienceId: experience?.id,
          parentExtraId: selectedExtra?.parentExtraId,
          dayIndex: selectedExtra?.dayIndex,
        });
      }

      return acc;
    }, []);
  };

  return (
    <div className="h-full">
      {!experience ? (
        <Selector
          dayIndex={dayIndex}
          type="experience"
        />
      ) : (
        <ComposeDayItemCard>
          <a
            href={`/experiences/${experience?.id}?source=trip`}
            target="_blank"
            className={"hover:text-blue-700"}
            rel="noreferrer"
          >
            <img
              src={experience?.imageUrl ?? experience?.pictures?.[0]?.imageUrl ?? '/img/hotel-image-placeholder.png'}
              onError={ev => ev.target.src = '/img/hotel-image-placeholder.png'}
              className="absolute inset-0 -z-10 h-full w-full object-cover brightness-150 blur-sm rounded-lg"
            />

            <div className="relative">
              {experience?.durationDays > 1 && (
                <div className="absolute bottom-0 right-0">
                  <KoobBadge
                    color="orange"
                    size={'sm'}
                  >
                    {experience.dayIndex}/{experience.durationDays}
                  </KoobBadge>
                </div>
              )}

              <div className="flex space-x-2 pr-1.5">
                <div>
                  <div className="relative h-12 w-12 rounded overflow-hidden">
                    <img
                      src={experience?.imageUrl ?? experience?.pictures?.[0]?.imageUrl ?? '/img/hotel-image-placeholder.png'}
                      onError={ev => ev.target.src = '/img/hotel-image-placeholder.png'}
                      className="absolute inset-0 h-full w-full object-cover rounded"
                    />
                  </div>
                </div>

                <div>
                  <div className="text-sm font-medium text-gray-900">
                    {experience.name}
                  </div>

                  {(!isLoadingPrice) && (
                    <div className="flex justify-between pr-1.5">
                      <div className="text-sm font-medium">
                        {(experience?.dayIndex === 1 && experienceTotalPrice) ? (
                          <div className="text-xs text-blue-500">
                            {formatCurrency({
                              amount: experienceTotalPrice?.price,
                              currency: experiencePrice?.currency,
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}

                </div>
              </div>
              <div>
                {(!experience?.dayIndex || experience.dayIndex === 1) &&
                  globalExtrasCount > 0 && (
                    <ComposeDayExperienceOptionsButton
                      label={t('managedOptions', { type: 'Global' })}
                      count={globalExtrasCount}
                      onSave={() => {
                        setShowExtrasModal(true);
                        setShowDayOptions(false);
                      }}
                    />
                  )}

                  {experience?.dayIndex && dayExtrasCount > 0 && (
                    <ComposeDayExperienceOptionsButton
                      label={t('managedOptions', {
                        type: `${t('day')} ${experience?.dayIndex}`,
                      })}
                      count={dayExtrasCount}
                      type="day"
                      onSave={() => {
                        setShowDayOptions(true);
                        setShowExtrasModal(true);
                      }}
                    />
                )}
              </div>
            </div>
          </a>
        </ComposeDayItemCard>
      )}

      {showExtrasModal && (
        <ExperienceExtrasModal
          open={showExtrasModal}
          setOpen={setShowExtrasModal}
          experience={experience}
          experienceComposition={experienceComposition}
          extrasScope={showDayOptions ? ExtraScopeEnum.DAY : ExtraScopeEnum.GLOBAL}
          extras={currentExtrasList}
          selectedExtras={formatSelectedExtras()}
          prices={experiencePrice?.extras}
          currency={experiencePrice?.currency}
          onContinue={()=> {}}
          showPaxUnmetWarning={false}
          dayIndex={dayIndex + 1}
          readOnly={true}
          source="trip"
        />
      )}

    </div>
  );
}

function ComposeDaySelectorHotel({ dayIndex, hotel, date, getHotelDayIndex }) {
  const hotelDayIndex = useMemo(() => {
    return getHotelDayIndex(hotel, dayIndex);
  }, [hotel]);
  const {setCompareVersionTotalPrice} = useTripDesigner();

  const requestFilters = {
    hotels: [hotel?.id],
    startAt: moment(date).format('YYYY-MM-DD'),
    endAt: moment(date).add(1, 'day').format('YYYY-MM-DD'),
    rooms: [],
    cities: [],
    countries: [],
    kinds: [],
    regions: [],
    styles: [],
    sustainability: null,
  };

  const { data: priceData, reload: reloadPrice, isLoading } = useAsync({
    promiseFn: getHotels,
    filters: requestFilters,
    skip: !hotel?.id,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
    onResolve: (data) => {
      if (data?.data?.state === 'finished') {
        const hotelPrice =  data?.data?.data?.[0];
        setCompareVersionTotalPrice(prev => {
          return updateTotalPrice(prev, hotelPrice);
        });
      }
    },
    onReject: () => {
      setTimeout(() => reloadPrice(), 5000);
    }
  });
  const hotelPriceData = priceData?.data?.data?.[0];

  return !hotel ? (
    <div className="h-full">
      <Selector
        dayIndex={dayIndex}
        type="hotel"
      />
    </div>
  ) : (
    <ComposeDayItemCard>
      <a
        href={`/hotels/${hotel?.id}?source=trip`}
        target="_blank"
        className={"hover:text-blue-700"}
        rel="noreferrer"
      >
      <img
        src={hotel?.imageUrl ?? hotel?.primaryAttachment?.imageUrl ?? '/img/hotel-bg-placeholder.png'}
        onError={ev => ev.target.src = '/img/hotel-bg-placeholder.png'}
        className="absolute inset-0 -z-10 h-full w-full object-cover brightness-150 blur-lg rounded-lg"
      />

      <div className="relative">

        {hotelDayIndex?.total >= 1 && (
          <div className="absolute bottom-0 right-0">
            <KoobBadge
              color="orange"
              size={'sm'}
            >
              {hotelDayIndex.current}/{hotelDayIndex.total}
            </KoobBadge>
          </div>
        )}

        <div className="flex space-x-2 pr-1.5">
          <div>
            <div className="relative h-10 w-10 rounded overflow-hidden">
              <img
                src={hotel?.imageUrl ?? hotel?.primaryAttachment?.imageUrl ?? '/img/hotel-image-placeholder.png'}
                onError={ev => ev.target.src = '/img/hotel-image-placeholder.png'}
                className="absolute inset-0 h-full w-full object-cover rounded"
              />
            </div>
          </div>
          <div>
            <div className="text-xs font-medium text-gray-900">
              {hotel?.displayName}
            </div>

            <div className="flex pr-1.5 items-center">
              <div className="text-sm font-medium mt-2">
              {(!hotelPriceData?.price && isLoading) || priceData?.data?.state !== 'finished' ? (
                  <Spinner size={15}/>
                ) : (
                  <>
                    {hotelPriceData?.price && (
                      <div className="text-xs text-blue-600">
                        {formatCurrency({
                          amount: hotelPriceData.price,
                          currency: hotelPriceData.currency,
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-1 text-xs">
          <div className="text-gray-500">
            {hotel?.address}
          </div>
        </div>
      </div>
      </a>
    </ComposeDayItemCard>
  )
}

export default function ComposeDayReadOnly({
  dayIndex,
  date,
  getHotelDayIndex,
  experience,
  extras = [],
  hotel
}) {
  const { t } = useTranslation('tripDesigner');

  const program = experience?.programs
    && experience?.programs[(experience?.dayIndex ?? 1) - 1]
    && experience?.programs[(experience?.dayIndex ?? 1) - 1];

  const accomodationType = program?.accomodationType;
  const accomodationName = program?.accomodationExperience?.name;

  return (
    <div className="py-2">
      <ExperienceProgramContentHeader
        title={`${t('compose.day')} ${dayIndex+1}`}
        date={date}
        location={experience?.inCity ? `${experience?.inCity?.title}, ${experience?.inCity?.country?.title}` : '--'}
      />

      <div className="mt-2 grid grid-cols-6 gap-1">
        <div className="col-span-3">
          <ComposeDaySelectorExperience
            dayIndex={dayIndex}
            experience={experience}
            extras={extras}
            date={date}
          />
        </div>

        <div className="col-span-3">
          {Boolean(hotel)
            ? (
              <ComposeDaySelectorHotel
                dayIndex={dayIndex}
                hotel={hotel}
                getHotelDayIndex={getHotelDayIndex}
                date={date}
              />
            ) : (
              accomodationType === ProgramAccommodationTypeEnum.NIGHT_TRANSFER ? (
                <div className="bg-gray-200 cursor-not-allowed h-full p-5 rounded-md border border-gray-300">
                  <div className="h-full flex justify-center items-center">
                    <div className="text-center text-gray-500 text-sm">
                      {accomodationName}
                    </div>
                  </div>
                </div>
                ) : (
                <div className="bg-gray-200 cursor-not-allowed h-full p-5 rounded-md border border-gray-300">
                  <div className="h-full flex justify-center items-center">
                    <div className="text-center text-gray-500 text-sm">
                      {accomodationType === ProgramAccommodationTypeEnum.FREE_ACCOMMODATION
                        ? t('compose.hotelWillBeBookedOnMyOwn')
                        : t('compose.noAccomodationSelected')}
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      </div>

      {experience?.dayIndex === experience?.programs?.length && (
        <SeparatorLine />
      )}
    </div>
  )
}
