import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ComposeDayExperience from './ComposeDayExperience';
import ComposeDayHotel from './ComposeDayHotel';
import ComposeDayInsertBetween from './ComposeDayInsertBetween';
import ComposeDayItemSelectorDisabled from './ComposeDayItemSelectorDisabled';
import ComposeDayHotelFreeAccomodationChoice from './ComposeDayHotelFreeAccomodationChoice';
import ExperienceProgramContentHeader from '../../../Experiences/Partials/ExperienceProgramContentHeader';

export default function ComposeDay({
  dayIndex,
  date,
  addHotel,
  getHotelDayIndex,
  setOptions,
  currentVersion,
  formatExtras,
  setFormatExtras,
  finalizing
}) {
  const { t } = useTranslation('tripDesigner');
  const [{ value: experience }] = useField(`days[${dayIndex}].experience`);
  const [{ value: bookOnMyOwnValue }, , { setValue: setBookOnMyOwn }] = useField(`days[${dayIndex}].hotelBookedOnMyOwn`);

  const program = experience?.programs?.[(experience?.dayIndex ?? 1) - 1];

  const accomodationType = program?.accomodationType;
  const accomodationName = program?.accomodationExperience?.name;

  const needsHotel =
    !['nightTransfer', 'noAccomodation', 'freeAccomodation'].includes(accomodationType)
    || (accomodationType === 'freeAccomodation' && bookOnMyOwnValue === false);

  const hotelSelectorComponent = () => {
    if (needsHotel) {
      return (
        <ComposeDayHotel
          dayIndex={dayIndex}
          addHotel={addHotel}
          date={date}
          getHotelDayIndex={getHotelDayIndex}
        />
      );
    }

    if (accomodationType === 'freeAccomodation') {
      return bookOnMyOwnValue === true ? (
        <ComposeDayItemSelectorDisabled>
          {t('compose.hotelWillBeBookedOnMyOwn')}

          <button
            onClick={() => setBookOnMyOwn(undefined)}
            className="mt-1.5 border-none text-orange-500"
          >
            {t('compose.resetChoice')}
          </button>
        </ComposeDayItemSelectorDisabled>
      ) : (
        <ComposeDayHotelFreeAccomodationChoice dayIndex={dayIndex} />
      );
    }

    if (accomodationType === 'nightTransfer') {
      return (
        <ComposeDayItemSelectorDisabled>
          {accomodationName}
        </ComposeDayItemSelectorDisabled>
      );
    }

    return (
      <ComposeDayItemSelectorDisabled>
        {t('compose.noAccomodation')}
      </ComposeDayItemSelectorDisabled>
    );
  };

  return (
    <div>
      <ComposeDayInsertBetween
        dayIndex={dayIndex}
        position="before"
        experience={experience}
      />

      <ExperienceProgramContentHeader
        title={`${t('compose.day')} ${dayIndex+1}`}
        date={date}
        location={
          experience?.inCity
            ? `${experience?.inCity?.title}, ${experience?.inCity?.country?.title}`
            : '--'
        }
      />

      <div className="my-3 grid grid-cols-7 gap-1">
        <div className="col-span-4">
          <ComposeDayExperience
            dayIndex={dayIndex}
            date={date}
            setOptions={setOptions}
            currentVersion={currentVersion}
            formatExtras={formatExtras}
            setFormatExtras={setFormatExtras}
            finalizing={finalizing}
          />
        </div>

        <div className="col-span-3">
          {hotelSelectorComponent()}
        </div>
      </div>

      <ComposeDayInsertBetween
        dayIndex={dayIndex}
        position="after"
        experience={experience}
      />
    </div>
  )
}
